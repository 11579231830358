import React, { createContext, useEffect, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import getQueryParam from '../utils/getQueryParam';
import axios from 'axios';
import { TError, TLocale } from 'models';
import { deleteCookie, getCookie, getCookieDomain, prepareAuthData, setCookie, translate } from '../utils';

type TOnError = (error: TError[]) => void;

const DEFAULT_LOCALE = 'pl';
const TENANT = getQueryParam('tenant') || window._env_.TENANT;
const BASE_API_URL = window._env_.API_IDENTIFIER_URL;
const GENERIC_ERROR = { code: '', message: 'Something goes wrong!' };
const REFRESH_TOKEN_BEFORE_EXPIRE_TIME = 300000; // 5 mins before expire time

const AccountContext = createContext({
  login: (username: string, password: string, onError: TOnError, onLoginChallengePassword: () => void) => {},
  logout: () => {},
  resetPassword: (username: string, onError: TOnError, onSuccess: (data: unknown) => void) => {},
  updatePassword: (confirmation_code: string, password: string, username: string, onError: TOnError, onSuccess: () => void) => {},
  challengePassword: (password: string, onError: TOnError, onSuccess: () => void) => {},
  locale: DEFAULT_LOCALE,
  tenant: TENANT,
  isAuthenticated: -1,
  challengeData: null,
  setLocale: (lang: TLocale) => {},
});

const Account = (props: { children: React.ReactNode }) => {
  useEffect(() => {

    //Check Login State
    if (getQueryParam('logout')) {
      logout();
    } else if (getQueryParam('resetPassword')) {
      localStorage.removeItem('authentication_result');
      setLoggedIn(0);
    } else if (getCookie('authentication_result')) {
      preflightCheck();
    } else {
      setLoggedIn(0);
    }

    const lang = localStorage.getItem('locale');

    //Check language
    if (lang) {
      updateLocale(lang as TLocale);
      sendCurrentLocaleToParent(lang as TLocale);
    } else {
      setCurrentLocale(DEFAULT_LOCALE);
    }

    // eslint-disable-next-line
  }, []);

  const [loggedIn, setLoggedIn] = useState(-1);
  const [locale, updateLocale] = useState(localStorage.getItem('locale') || 'pl');
  const [challengeData, updateChallengeData] = useState<{username: string, session: string} | null>(null);

  const storeLogin = (data: Record<string, any>) => {
    let broker = getQueryParam('broker');

    if (broker) {
      data.broker = broker;
    }

    setCookie('authentication_result', JSON.stringify(data), 365, getCookieDomain());

    window.parent?.postMessage(
      JSON.stringify({
        name: 'login',
        error: false,
        message: data,
      }),
      '*'
    );

    setLoggedIn(1);
  };

  const preflightCheck = () => {
    const now = new Date().getTime();

    const parsedAuthData = JSON.parse(getCookie('authentication_result') || '');

    if (!parsedAuthData) {
      logout();
    }

    const decodedToken: {exp: number, username: string} = jwtDecode(parsedAuthData.access_token);

    const expiresAt = decodedToken.exp * 1000; // Convert to the milliseconds
    const username = decodedToken.username;
    const refreshToken = parsedAuthData.refresh_token;

    if (!expiresAt || (now - (parsedAuthData.user_time_diff || 0)) >= (expiresAt - REFRESH_TOKEN_BEFORE_EXPIRE_TIME)) {
      axios.post(`${BASE_API_URL}/token/refresh/${TENANT}`, {
        'username': username,
        'refresh_token': refreshToken,
      }, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      }).then(response => {
        const data = response.data;
        const authResults = {
          ...parsedAuthData,
          access_token: data?.authentication_result.access_token,
          id_token: data?.authentication_result.id_token,
        };
        storeLogin(prepareAuthData(authResults));
      }).catch(() => {
        logout();
      });
    } else {
      storeLogin(parsedAuthData || '');
    }
  };

  const login = (username: string, password: string, onError: TOnError, onLoginChallengePassword: () => void) => {
    axios.post(`${BASE_API_URL}/login/${TENANT}`, {
      'username': username,
      'password': password
    }, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => {
      const data = response.data;
      if (data) {
        if (data.challenge_name && data.challenge_name === 'NEW_PASSWORD_REQUIRED') {
          updateChallengeData({
            username: username,
            session: data.data?.session
          });
          onLoginChallengePassword();
        } else if (data?.authentication_result && data?.authentication_result.access_token) {
          storeLogin(prepareAuthData(data?.authentication_result));
        } else {
          onError(setGenericError(locale));
        }
      }
    }).catch(error => {
      if (error.response) {
        const data = error.response.data;
        if (data?.errors) {
          onError(data.errors);
        }
      } else {
        onError(setGenericError(locale));
      }
    });
  };

  const resetPassword = (username: string, onError: TOnError, onSuccess: (data: unknown) => void) => {
    axios.post(`${BASE_API_URL}/password/reset/${TENANT}`, {
      'username': username,
    }, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(response => {
      const data = response.data;
      onSuccess(data);
    }).catch(error => {
      if (error.response) {
        const data = error.response.data;
        if (data?.errors) {
          onError(data.errors);
        }
      } else {
        onError(setGenericError(locale));
      }
    });
  };

  const updatePassword = (confirmation_code: string, password: string, username: string, onError: TOnError, onSuccess: () => void) => {
    axios.post(`${BASE_API_URL}/password/update/${TENANT}`, {
      'confirmation_code': confirmation_code,
      'password': password,
      'username': username,
    }, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(() => {
      onSuccess();
    }).catch(error => {
      if (error.response) {
        const data = error.response.data;
        if (data?.errors) {
          onError(data.errors);
        }
      } else {
        onError(setGenericError(locale));
      }
    });
  };

  const challengePassword = (password: string, onError: TOnError, onSuccess: () => void) => {
    axios.post(`${BASE_API_URL}/auth/challenge/${TENANT}`, {
      challenge_name: 'NEW_PASSWORD_REQUIRED',
      challenge_responses: {
        'USERNAME': challengeData?.username,
        'NEW_PASSWORD': password,
        'user_attributes': {
          'name': challengeData?.username
        }
      },
      session: challengeData?.session
    }, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }).then(() => {
      onSuccess();
    }).catch(error => {
      if (error.response) {
        const data = error.response.data;
        if (data?.errors) {
          onError(data.errors);
        }
      } else {
        onError(setGenericError(locale));
      }
    });
  };

  const logout = () => {
    if (getCookie('authentication_result')) {
      const parsedAuthData = JSON.parse(getCookie('authentication_result') as string);
      axios.post(`${BASE_API_URL}/logout`, {}, {
        headers: {
          'Authorization': `${parsedAuthData.token_type} ${parsedAuthData.access_token}`,
        },
      }).then().catch(error => console.error(error));
    }

    deleteCookie('authentication_result', getCookieDomain());
    setLoggedIn(0);
    window.parent?.postMessage(
      JSON.stringify({
        name: 'logout',
        error: false,
        message: 'successfully logout'
      }),
      '*'
    );
  };

  const setCurrentLocale = (lang: TLocale) => {
    localStorage.setItem('locale', lang);
    updateLocale(lang);
    sendCurrentLocaleToParent(lang);
  };

  const sendCurrentLocaleToParent = (lang: TLocale) => {
    window.parent?.postMessage(
      JSON.stringify({
        name: 'lang',
        error: false,
        message: lang
      }),
      '*'
    );
  };

  return (
    <AccountContext.Provider value={{
      login,
      logout,
      resetPassword,
      updatePassword,
      challengePassword,
      isAuthenticated: loggedIn,
      locale,
      tenant: TENANT,
      // @ts-ignore
      challengeData,
      setLocale: (lang: TLocale) => setCurrentLocale(lang)
    }}>
      {props.children}
    </AccountContext.Provider>
  );
};

const setGenericError = (locale: string) => {
  if (translate({ key: 'sso.errors.default' })) {
    return [
      {
        ...GENERIC_ERROR,
        message: translate({ key: 'sso.errors.default' })
      }
    ];
  } else {
    return [GENERIC_ERROR];
  }
};

export { Account, AccountContext };
