import React, { useContext, useState } from 'react';
import {
  BodyBlock,
  BodyBlockBody,
  FormGroup,
  FormGroupLabel,
  LinkPseudo,
  BodyControls,
  BodyBlockFooter,
  ButtonSubmit,
  IconNextStep,
  BodyBlockHeader
} from '../styles';
import { TStep } from '../index';
import { AccountContext } from 'src/components/Accounts';
import { TError } from 'models';
import BlockHead from '../common/BlockHead';
import renderErrors from '../common/Errors';
import Input from '../components/Input';
import { translate } from '../../../utils';

interface Props {
  onChangeStep: (step: TStep) => void,
  isPasswordChanged?: boolean,
}

export const BodyLogin = ({ onChangeStep, isPasswordChanged }: Props) => {
  const [form, updateForm] = useState({
    username: '',
    password: '',
    errorField: '',
    errorText: '',
    showPassword: false,
    errorApi: []
  });

  const { login } = useContext(AccountContext);

  const onLogin = (event: React.FormEvent) => {
    if (event) {
      event.preventDefault();
    }
    login(form.username.trim(), form.password.trim(), onLoginError, onLoginChallengePassword);
  };

  const onLoginError = (err: TError[]) => {
    if (err?.length) {
      onChangeInput('errorApi', err);
    }
  };

  const onLoginChallengePassword = () => {
    onChangeStep('challenge_password');
  };

  const onChangeInput = (name: string, value: string | boolean | TError[]) => {
    updateForm({
      ...form,
      errorApi: [],
      [name]: name === 'username' && typeof value === 'string' ? value.toLowerCase() : value
    });
  };

  return (
    <>
      {isPasswordChanged && <BodyBlockHeader>{translate({ key: 'sso.login.password_changed' })}</BodyBlockHeader>}
      <BodyBlock>
        <BlockHead />
        <BodyBlockBody onSubmit={onLogin}>
          {form.errorApi?.length ? renderErrors(form.errorApi) : null}
          <FormGroup>
            <FormGroupLabel>{translate({ key: 'sso.login.login' })}</FormGroupLabel>
            <Input
              type="email"
              value={form.username}
              required={true}
              className="testing__input-email"
              handleChange={(value) => onChangeInput('username', value)}
            />
          </FormGroup>
          <FormGroup>
            <FormGroupLabel>{translate({ key: 'sso.login.password' })}</FormGroupLabel>
            <Input
              type="password"
              value={form.password}
              required={true}
              className="testing__input-password"
              handleChange={(value) => onChangeInput('password', value)}
            />
          </FormGroup>
          <BodyControls>
            <ButtonSubmit $isCustomWidth type="submit" className="testing__button-submit">
              <>{translate({ key: 'sso.buttons.login' })} <IconNextStep icon="arrow_left" /></>
            </ButtonSubmit>
          </BodyControls>
        </BodyBlockBody>
      </BodyBlock>
      <BodyBlockFooter>
        <LinkPseudo className="testing__button-forgot" onClick={() => onChangeStep('forgot')}>{translate({ key: 'sso.login.forgot_password' })}</LinkPseudo>
      </BodyBlockFooter>
    </>
  );
};
