import React, { useContext, useEffect, useState } from 'react';
import Auth from './AuthInsly3';
import { ErrorWrapper, Loader, LoadingScreen } from './styles';
import { getTranslations } from '../config/translation';
import { TError, TFetchStatus } from 'models';
import renderErrors from './AuthInsly3/common/Errors';
import { AccountContext } from './Accounts';
import {
  Body,
  Header,
  Language,
  LanguageIcon,
  LanguageTitle,
  LoginPage,
  Logo,
  LogoTitle
} from './AuthInsly3/styles';
import logo from '../assets/logo.png';

const Index = () => {
  const [networkStatus, updateNetworkStatus] = useState<TFetchStatus>(null);
  const [errors, updateErrors] = useState<TError[]>([]);

  const { locale, setLocale } = useContext(AccountContext);

  const toggleLocal = () => {
    if (locale === 'pl') {
      setLocale('en');
    } else {
      setLocale('pl');
    }
  };

  useEffect(() => {
    getTranslations({
      updateNetworkStatus,
      onError: updateErrors
    });
  }, [locale]);

  if (networkStatus === 'failed') {
    return (
      <LoadingScreen>
        <ErrorWrapper>
          {renderErrors(errors)}
        </ErrorWrapper>
      </LoadingScreen>
    );
  }

  return (
    <>
      <LoginPage>
        <Header>
          <Logo>
            <img src={logo} alt="insly" />
            <LogoTitle>INSLY</LogoTitle>
          </Logo>
          <Language onClick={toggleLocal}>
            <LanguageTitle>{locale === 'pl' ? 'Polski' : 'English'}</LanguageTitle>
            <LanguageIcon icon="globe" />
          </Language>
        </Header>
        <Body>
          {networkStatus === 'loading' && (
            <LoadingScreen>
              <Loader />
            </LoadingScreen>
          )}
          <Auth networkStatus={networkStatus} />
        </Body>
      </LoginPage>
    </>
  );
};

export default Index;
