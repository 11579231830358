import { Error, ErrorMessage } from '../styles';
import React from 'react';
import { TError } from 'models';
import { translate } from '../../../utils';

const renderErrors = (items: TError[]) => items.map((item, index) => (
  <Error key={index}>
    <ErrorMessage>{(item.code && (translate({ key: `sso.errors.${item.code}` })) !== `sso.errors.${item.code}`) ? translate({ key: `sso.errors.${item.code}` }) : item.message}</ErrorMessage>
  </Error>
));

export default renderErrors;
