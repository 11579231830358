import styled from 'styled-components';
import { TFetchStatus } from 'models';
import background from '../../assets/background.svg';
import { SvgIcon, Button as ButtonDefault } from '@insly/qmt-reactjs-ui-lib';

export const LoginPage = styled.div`
  background: url(${background}) 0 0/contain no-repeat, white;
  min-height: 100vh;
  width: 100%;
`;

export const Header = styled.div`
  background: ${({ theme }) => theme.colors.white};
  min-height: 80px;
  border-bottom: 1px solid rgb(190, 190, 190);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 13px 24px 13px 0;
  
  img {
    display: block;
    width: 54px;
    height: 54px;
  }
`;

export const LogoTitle = styled.div`
  ${({ theme }) => theme.text.h3};
`;

export const Language = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`;

export const LanguageTitle = styled.div`
  ${({ theme }) => theme.text.small};
`;

export const LanguageIcon = styled(SvgIcon)`
  width: 24px;
  height: 24px;
`;

export const Body = styled.div`
  min-height: calc(100vh - 80px);
  display: grid;
  place-items: center;
`;

export const BodyBlockWrapper = styled.div<{ $networkStatus: TFetchStatus}>`
  ${({ theme }) => theme.text.normal};
  display: ${({ $networkStatus }) => $networkStatus === 'loading' && 'none'};
`;

export const BodyBlock = styled.div`
  border: 1px #BEBEBE solid;
  background-color: white;
  border-radius: 5px;
  padding: 40px 40px 18px;
`;

export const BodyBlockHead = styled.div`
  ${({ theme }) => theme.text.h2};
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
`;

export const BodyBlockBody = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 0 0;
`;

export const BodyBlockDesc = styled.div`

`;

export const BodyBlockFooter = styled.div`
  padding: 12px 40px 12px 0;
  text-align: right;
`;

export const BodyBlockHeader = styled(BodyBlockFooter)`
  padding: 12px 40px 18px 0;
  text-align: center;
  ${({ theme }) => theme.text.headerH2};
`;

export const LinkPseudo = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.primary};
  &:hover {
    text-decoration: underline;
  }
`;

export const FormGroup = styled.div`
  display: flex;
  position: relative;
  ${({ theme }) => theme.text.label};
`;

export const FormGroupLabel = styled.label`
  position: absolute;
  left: 15px;
  top: 13px;
  color: ${({ theme }) => theme.colors.subtle};
`;

export const FormInput = styled.input`
  flex: 2;
  border: 1px #BEBEBE solid;
  color: #535352;
  padding: 5px 14px 0;
  font-size: 14px;
  border-radius: 5px;
  display: block;
  min-width: 640px;
  height: 64px;
  line-height: 1.42857;
  background-color: #fff;
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  transition: box-shadow ease-in-out 0.15s;
  &:focus {
    border: 2px #BEBEBE solid;
    outline: 0;
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
  }
`;

export const ButtonSubmit = styled(ButtonDefault)<{ $isCustomWidth?: boolean }>`
  min-width: ${({ $isCustomWidth }) => $isCustomWidth && '300px'};
  order: 1;
`;

export const ButtonCancel = styled(ButtonDefault)`
  background: #DDDDDD;
  color: ${({ theme }) => theme.colors.black};
`;

export const IconNextStep = styled(SvgIcon)`
  stroke: ${({ theme }) => theme.colors.white};
  transform: rotate(180deg);
  width: 24px;
  height: 24px;
  margin-left: 8px;
  flex-shrink: 0;
`;

export const BodyDesc = styled.p`
  font-family: ${({ theme }) => theme.font.light};
  color: #444;
  margin: 20px 0 0;
`;

export const BodyControls = styled.div<{ padding?: boolean }>`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  ${({ padding }) => padding ? `
    padding: 30px 50px;
  ` : null}
`;

export const Error = styled.dl`
  background-color: #FF484F;
  color: #fff;
  line-height: 25px;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 6px;
`;

export const ErrorMessage = styled.dd`
  ${({ theme }) => theme.text.normal};
`;
