import React, { useContext, useState } from 'react';
import {
  BodyControls,
  BodyDesc,
  BodyBlockBody,
  FormGroup,
  FormGroupLabel,
  BodyBlock,
  BodyBlockDesc,
  ButtonCancel,
  ButtonSubmit
} from '../styles';
import { TStep } from '../index';
import { TError } from 'models';
import { AccountContext } from 'src/components/Accounts';
import renderErrors from '../common/Errors';
import Input from '../components/Input';
import { translate } from '../../../utils';
import BlockHead from '../common/BlockHead';

interface Props {
  onChangeStep: (step: TStep) => void,
}

export const BodyChallengePassword = ({ onChangeStep }: Props) => {

  const { challengePassword } = useContext(AccountContext);

  const [form, updateForm] = useState<{
    password: string,
    password_repeat: string,
    errorApi: TError[],
  }>({
    password: '',
    password_repeat: '',
    errorApi: [],
  });

  const onSubmit = () => {
    challengePassword(form.password.trim(), onAPIError, onSuccess);
  };

  const onSuccess = () => {
    onChangeStep('reset_success');
  };

  const onAPIError = (err: TError[]) => {
    if (err?.length) {
      onChangeInput('errorApi', err);
    }
  };

  const onChangeInput = (name: string, value: string | boolean | TError[]) => {
    updateForm({
      ...form,
      errorApi: [],
      [name]: value
    });
  };

  const onValidate = (event: React.FormEvent) => {
    if (event) {
      event.preventDefault();
    }

    const { password, password_repeat } = form;
    if (password && password_repeat) {
      if (password !== password_repeat) {
        updateForm({
          ...form,
          errorApi: [{ code: '', message: translate({ key: 'sso.reset.error.passwords_not_match' }) }],
        });
      } else {
        onSubmit();
      }
    }

  };

  const renderForm = () => (
    <>
      <BodyBlockDesc>
        <BlockHead title={translate({ key: 'sso.reset.title' })} />
        <BodyDesc>
          {translate({ key: 'sso.challenge_password.desc' })}
        </BodyDesc>
      </BodyBlockDesc>
      <BodyBlockBody onSubmit={onValidate}>
        {form.errorApi?.length ? renderErrors(form.errorApi) : null}
        <FormGroup>
          <FormGroupLabel>{translate({ key: 'sso.reset.password' })}</FormGroupLabel>
          <Input
            type="password"
            value={form.password}
            required={true}
            handleChange={(value) => onChangeInput('password', value)}
          />
        </FormGroup>
        <FormGroup>
          <FormGroupLabel>{translate({ key: 'sso.reset.password_repeat' })}</FormGroupLabel>
          <Input
            type="password"
            value={form.password_repeat}
            required={true}
            handleChange={(value) => onChangeInput('password_repeat', value)}
          />
        </FormGroup>
        <BodyControls>
          <ButtonSubmit size="l" type="submit">
            {translate({ key: 'sso.buttons.update' })}
          </ButtonSubmit>
          <ButtonCancel size="l" type="reset" onClick={() => onChangeStep('login')}>
            {translate({ key: 'sso.buttons.cancel' })}
          </ButtonCancel>
        </BodyControls>
      </BodyBlockBody>
    </>
  );

  return (
    <BodyBlock>
      {renderForm()}
    </BodyBlock>
  );

};
