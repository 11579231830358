import { BodyBlockHead } from '../styles';
import React from 'react';
import { translate } from '../../../utils';

const BlockHead = ({ title = translate({ key: 'sso.login.title' }) }: { title?: string }) => (
  <BodyBlockHead>
    {title}
  </BodyBlockHead>
);

export default BlockHead;
