import { TError, TFetchStatus } from 'models';
import { processResponseErrors } from '../utils';
import axios from 'axios';
import apiConfig from './api';

const API_TRANSLATION_URL = `${apiConfig.MASTER}/locale/lang`;

export let translations: Record<string, string> = {};

export const getTranslations = (
  {
    locale,
    updateNetworkStatus,
    onError
  } : {
    locale?: string,
    updateNetworkStatus: (status: TFetchStatus) => void,
    onError: (errors: TError[]) => void,
  }) => {
  locale = locale || localStorage.getItem('locale') || window._env_.DEFAULT_LOCALE;
  updateNetworkStatus('loading');

  axios.get(`${API_TRANSLATION_URL}/${locale}?type=2`).then(response => {
    if (response.data) {
      updateTranslations(response.data.translations);
      updateNetworkStatus('success');
    }
  }).catch(error => {
    console.error(error);
    onError(processResponseErrors(error));
    updateNetworkStatus('failed');
  });

};

export const updateTranslations = (data: Record<string, string>) => {
  translations = data;
};
