import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

window.parent?.postMessage(
  JSON.stringify({
    name: 'iframeLoaded',
    error: false,
    message: null,
  }),
  '*'
);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

