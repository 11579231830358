import React, { useContext, useState } from 'react';
import {
  BodyControls,
  BodyDesc,
  BodyBlockBody,
  FormGroup,
  FormGroupLabel,
  BodyBlock,
  BodyBlockDesc,
  ButtonCancel,
  ButtonSubmit
} from '../styles';
import { TStep } from '../index';
import { TError } from 'models';
import { AccountContext } from '../../Accounts';
import renderErrors from '../common/Errors';
import Input from '../components/Input';
import { translate } from '../../../utils';
import BlockHead from '../common/BlockHead';

interface Props {
  onChangeStep: (step: TStep) => void,
  email?: string,
}

export const BodyResetPassword = ({ onChangeStep, email }: Props) => {

  const { updatePassword } = useContext(AccountContext);

  const [form, updateForm] = useState<{
    email: string,
    code: string,
    password: string,
    password_repeat: string,
    errorApi: TError[],
  }>({
    email: email || '',
    code: '',
    password: '',
    password_repeat: '',
    errorApi: [],
  });

  const onReset = () => {
    updatePassword(form.code.trim(), form.password.trim(), form.email.trim(), onAPIError, onSuccessReset);
  };

  const onSuccessReset = () => {
    onChangeStep('reset_success');
  };

  const onAPIError = (err: TError[]) => {
    if (err?.length) {
      onChangeInput('errorApi', err);
    }
  };

  const onChangeInput = (name: string, value: string | boolean | TError[]) => {
    updateForm({
      ...form,
      errorApi: [],
      [name]: value
    });
  };

  const onValidateReset = (event: React.FormEvent) => {
    if (event) {
      event.preventDefault();
    }

    const { password, password_repeat } = form;
    if (password && password_repeat) {
      if (password !== password_repeat) {
        updateForm({
          ...form,
          errorApi: [{ code: '', message: translate({ key: 'sso.reset.error.passwords_not_match' }) }],
        });
      } else {
        onReset();
      }
    } else {
      updateForm({
        ...form,
        errorApi: [{ code: '', message: translate({ key: 'sso.reset.error.passwords_not_match' }) }],
      });
    }

  };

  const renderResetForm = () => (
    <>
      <BodyBlockDesc>
        <BlockHead title={translate({ key: 'sso.reset.title' })} />
        <BodyDesc>
          {translate({ key: 'sso.reset.desc2' })}
        </BodyDesc>
      </BodyBlockDesc>
      <BodyBlockBody onSubmit={onValidateReset}>
        {form.errorApi?.length ? renderErrors(form.errorApi) : null}
        {email ? null : (
          <FormGroup>
            <FormGroupLabel>{translate({ key: 'sso.login.email' })}</FormGroupLabel>
            <Input
              type="email"
              value={form.email}
              required={true}
              handleChange={(value) => onChangeInput('email', value)}
            />
          </FormGroup>
        )}
        <FormGroup>
          <FormGroupLabel>{translate({ key: 'sso.reset.code' })}</FormGroupLabel>
          <Input
            value={form.code}
            required={true}
            handleChange={(value) => onChangeInput('code', value)}
          />
        </FormGroup>
        <FormGroup>
          <FormGroupLabel>{translate({ key: 'sso.reset.password' })}</FormGroupLabel>
          <Input
            type="password"
            value={form.password}
            required={true}
            handleChange={(value) => onChangeInput('password', value)}
          />
        </FormGroup>
        <FormGroup>
          <FormGroupLabel>{translate({ key: 'sso.reset.password_repeat' })}</FormGroupLabel>
          <Input
            type="password"
            value={form.password_repeat}
            required={true}
            handleChange={(value) => onChangeInput('password_repeat', value)}
          />
        </FormGroup>
        <BodyControls>
          <ButtonSubmit size="l" type="submit">
            {translate({ key: 'sso.buttons.update' })}
          </ButtonSubmit>
          <ButtonCancel size="l" type="reset" onClick={() => onChangeStep('login')}>
            {translate({ key: 'sso.buttons.cancel' })}
          </ButtonCancel>
        </BodyControls>
      </BodyBlockBody>
    </>
  );

  return (
    <BodyBlock>
      {renderResetForm()}
    </BodyBlock>
  );

};
