import React, { useContext, useState } from 'react';
import {
  BodyControls,
  BodyDesc,
  BodyBlockBody,
  FormGroup,
  FormGroupLabel,
  BodyBlock,
  BodyBlockDesc,
  ButtonSubmit,
  ButtonCancel,
} from '../styles';
import { TStep } from '../index';
import { TError } from 'models';
import BlockHead from '../common/BlockHead';
import { AccountContext } from 'src/components/Accounts';
import renderErrors from '../common/Errors';
import Input from '../components/Input';
import { translate } from '../../../utils';
import { BodyResetPassword } from './ResetPassword';

interface Props {
  onChangeStep: (step: TStep) => void,
}

export const BodyForgot = ({ onChangeStep }: Props) => {

  const { resetPassword } = useContext(AccountContext);

  const [resetStep, updateResetStep] = useState('forgot');
  const [form, updateForm] = useState<{
    email: string,
    code: string,
    password: string,
    password_repeat: string,
    errorApi: TError[],
  }>({
    email: '',
    code: '',
    password: '',
    password_repeat: '',
    errorApi: [],
  });

  const onForgot = (event: React.FormEvent) => {
    if (event) {
      event.preventDefault();
    }
    resetPassword(form.email.trim(), onAPIError, onSuccessForgot);
  };

  const onSuccessForgot = () => {
    updateResetStep('reset');
  };

  const onAPIError = (err: TError[]) => {
    if (err?.length) {
      onChangeInput('errorApi', err);
    }
  };

  const onChangeInput = (name: string, value: string | boolean | TError[]) => {
    updateForm({
      ...form,
      errorApi: [],
      [name]: value
    });
  };

  const renderForgotForm = () => (
    <BodyBlock>
      <BlockHead title={translate({ key: 'sso.reset.title' })} />
      <BodyBlockDesc>
        <BodyDesc>
          {translate({ key: 'sso.forgot.desc' })}
        </BodyDesc>
      </BodyBlockDesc>
      <BodyBlockBody onSubmit={onForgot}>
        {form.errorApi?.length ? renderErrors(form.errorApi) : null}
        <FormGroup>
          <FormGroupLabel>{translate({ key: 'sso.login.email' })}</FormGroupLabel>
          <Input
            type="email"
            value={form.email}
            required={true}
            handleChange={(value) => onChangeInput('email', value)}
          />
        </FormGroup>
        <BodyControls>
          <ButtonSubmit size="l" type="submit">
            {translate({ key: 'sso.buttons.reset' })}
          </ButtonSubmit>
          <ButtonCancel size="l" type="reset" onClick={() => onChangeStep('login')}>
            {translate({ key: 'sso.buttons.cancel' })}
          </ButtonCancel>
        </BodyControls>
      </BodyBlockBody>
    </BodyBlock>
  );

  const renderResetForm = () => (
    <BodyResetPassword onChangeStep={onChangeStep} email={form.email} />
  );

  return (
    <>
      {resetStep === 'forgot' ? renderForgotForm() : renderResetForm()}
    </>
  );

};
