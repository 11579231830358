import { LoaderAnimated } from '@insly/qmt-reactjs-ui-lib';
import styled from 'styled-components';
import { TFetchStatus } from 'models';

export const Loader = styled(LoaderAnimated)`
  margin: auto;
`;

export const LoadingScreen = styled.div<{ $networkStatus?: TFetchStatus}>`
  display: ${({ $networkStatus }) => $networkStatus === 'loading' ? 'none' : 'flex'};
  flex: 1 1 0%;
  justify-content: center;
  align-items: center;
`;

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: auto;
`;
