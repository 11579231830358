import React, { useState, useContext, useEffect } from 'react';
import { Button } from '@insly/qmt-reactjs-ui-lib';
import { AccountContext } from '../Accounts';
import { BodyLogin } from './body/Login';
import { BodyForgot } from './body/Forgot';
import { BodyBlockWrapper } from './styles';
import { BodyChallengePassword } from './body/ChallengePassword';
import getQueryParam from '../../utils/getQueryParam';
import { BodyResetPassword } from './body/ResetPassword';
import { TFetchStatus, TError } from 'models';
import { Loader, LoadingScreen } from '../styles';
import renderErrors from './common/Errors';

export type TStep = 'login' | 'forgot' | 'reset_password' | 'reset_success' | 'challenge_password';

const Auth = ({ networkStatus }: { networkStatus: TFetchStatus }) => {
  const [step, updateStep] = useState<TStep>(setInitialStep());
  const [errors, updateErrors] = useState<TError[]>([]);

  const { logout, isAuthenticated } = useContext(AccountContext);

  useEffect(() => {
    const handler = (event: { data: any }) => {
      if (typeof event.data === 'string' && event.data?.length) {
        processMessage(event.data);
      }
    };

    window.addEventListener('message', handler);

    // clean up
    return () => window.removeEventListener('message', handler);

    // eslint-disable-next-line
  }, []);

  const processMessage = (eventData: string) => {

    const data = JSON.parse(eventData);
    switch (data.name) {
      case 'error':
        if (!data.error) {
          updateErrors(data.message as TError[]);
        }
        break;
    }
  };

  if (isAuthenticated) {
    if (getQueryParam('debug') || errors.length) {
      return (
        <div>
          {errors.length > 0 && renderErrors(errors)}
          You are logged in.
          <Button onClick={logout}>Logout</Button>
        </div>
      );
    } else {
      return (
        <LoadingScreen $networkStatus={networkStatus}>
          <Loader />
        </LoadingScreen>
      );
    }
  }

  return (
    <BodyBlockWrapper $networkStatus={networkStatus}>
      {renderBody(step, updateStep)}
    </BodyBlockWrapper>
  );
};

const renderBody = (step: TStep, updateStep: (step: TStep) => void) => {
  switch (step) {
    case 'login':
      return (
        <BodyLogin onChangeStep={updateStep} />
      );
    case 'forgot':
      return (
        <BodyForgot onChangeStep={updateStep} />
      );
    case 'reset_password':
      return (
        <BodyResetPassword onChangeStep={updateStep} />
      );
    case 'reset_success':
      return (
        <BodyLogin isPasswordChanged onChangeStep={updateStep} />
      );
    case 'challenge_password':
      return (
        <BodyChallengePassword onChangeStep={updateStep} />
      );
  }

  return (
    <div>Error happens:)</div>
  );
};

const setInitialStep = () => {
  if (getQueryParam('resetPassword')) {
    return 'reset_password';
  }

  return 'login';
};

export default Auth;
