import React from 'react';
import { Account } from './components/Accounts';
import { theme } from './theme';
import { ThemeProvider } from 'styled-components';
import Index from './components/index';
import { ReactComponent as SVGSprite } from './assets/icons/icons.svg';

function App() {
  return (
    <Account>
      <ThemeProvider theme={theme}>
        <SVGSprite style={{ position: 'absolute', height: '0px', width: '0px' }} />
        <Index />
      </ThemeProvider>
    </Account>
  );
}

export default App;
