export const setCookie = (cname: string, cvalue: string, exdays: number, domain?: string) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = 'expires='+d.toUTCString();
  document.cookie = `${cname}=${cvalue};${expires}${domain ? `;domain=${domain}` : ''};path=/`;
};

export const getCookie = (cname: string) => {
  let name = cname + '=';
  let ca = document.cookie.split(';');
  for(let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }

  return '';
};

export const deleteCookie = (cname: string, domain?: string) => document.cookie = `${cname}=;expires=Thu, 01 Jan 1970 00:00:00 UTC}${domain ? `;domain=${domain}` : ''};path=/;`;

export const getCookieDomain = () => {
  if (window._env_.COOKIE_DOMAIN) {
    return window._env_.COOKIE_DOMAIN;
  }

  const locationHostName = window.location.hostname;

  const parts = locationHostName.split('.');
  const domain = parts.slice(-2).join('.');

  return domain;

};
