
import { useRef } from 'react';
import { FormInput } from '../styles';

type InputProps = {
  type?: string,
  value?: string,
  required?: boolean,
  className?: string,
  handleChange: (value: string) => void,
};

const REG_EX_TRIM = /^\s+|\s+$/g;

const Input = ({
  type,
  value,
  required,
  className,
  handleChange
}: InputProps) => {
  const TEXT_INPUT: React.MutableRefObject<any> = useRef(null);

  const onBlur = () => {
    let currentValue = TEXT_INPUT.current.value;

    if (typeof currentValue === 'string' && REG_EX_TRIM.test(currentValue)) { // if input has type "email" UI doesn't render it correctly, but send correct data when submit (https://github.com/facebook/react/issues/14897)
      currentValue = currentValue.trim();
      handleChange(currentValue);
    }
  };

  return (
    <FormInput
      type={type}
      value={value}
      required={required}
      className={className}
      onChange={(e) => handleChange(e.target.value)}
      onBlur={onBlur}
      ref={TEXT_INPUT}
    />
  );
};

export default Input;
